export const alphaNumericSpecial = "^[a-zA-Z0-9\\-\\_\\+\\ \\$\\#\\@\\%\\&\\(\\)\\!\\'\\^\\:\\;\\.\\?\\[\\]\\,\\{\\}]+$"
export const regexAlphaNumeric = '^[a-zA-Z0-9 ,+\\-$#@%&()!\'^_:;.?\\[\\]\\{\\}\\/="\\|\\\\ ]+$'
export const errMsgAlphaNumeric = ',+-$#@%&()!\'^_:;.?[]{}/="|\\'
export const regex3DigitWith3Decimal = '^[0-9]{0,3}(\\.[0-9]{0,3})?$'
export const regex4DigitWith2Decimal = '^[0-9]{0,4}(\\.[0-9]{0,2})?$'
export const regex6DigitWith2Decimal = '^[0-9]{0,6}(\\.[0-9]{0,2})?$'
export const regex6DigitWith4Decimal = '^[0-9]{0,6}(\\.[0-9]{0,4})?$'
export const regex7DigitWith1Decimal = '^[0-9]{0,7}(\\.[0-9]{0,1})?$'
export const regex7DigitWith2Decimal = '^[0-9]{0,7}(\\.[0-9]{0,2})?$'
export const regex7DigitWith4Decimal = '^[0-9]{0,7}(\\.[0-9]{0,4})?$'
export const regex7DigitWith7Decimal = '^[0-9]{0,7}(\\.[0-9]{0,7})?$'
export const regex9DigitWith2Decimal = '^[0-9]{0,9}(\\.[0-9]{0,2})?$'
export const regex9DigitWith9Decimal = '^[0-9]{0,9}(\\.[0-9]{0,9})?$'
export const regex13DigitWith2Decimal = '^[0-9]{0,13}(\\.[0-9]{0,2})?$'
export const regexDigitWithDecimal = '^[0-9]*\\.?([0-9])*?$'